import React, { useState, useRef, MouseEventHandler } from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'

interface Option {
  name: String
  handler: MouseEventHandler<HTMLButtonElement>
}

interface SplitButtonProperties {
  options: Option[]
  disabled?: boolean
}

export default function SplitButton({ options, disabled }: SplitButtonProperties) {
  const [open, setOpen] = useState<boolean>(false)
  const [index, setIndex] = useState<number>(0)
  const anchor = useRef<HTMLDivElement>(null)
  const handleSelect = (_: any, index: number) => {
    setIndex(index)
    setOpen(false)
  }

  const handleToggle = () => setOpen((open) => !open)
  const handleClose = (event: MouseEvent | TouchEvent) =>
    setOpen(!!anchor.current?.contains(event.target as HTMLElement))

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <ButtonGroup
          disabled={disabled}
          variant="contained"
          color="primary"
          ref={anchor}
          aria-label="split button"
        >
          <Button onClick={options[index].handler}>{options[index].name}</Button>
          <Button
            color="primary"
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchor.current} role={undefined} transition>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, current) => (
                      <MenuItem
                        key={current}
                        selected={current === index}
                        onClick={(event) => handleSelect(event, current)}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  )
}
