import { api } from '../store.api'
import { providesList } from 'store/store.utils'
import deserialize from 'util/deserialize'

export const openingsApi = api.injectEndpoints({
  endpoints: (build) => ({
    allOpenings: build.query({
      query(scope) {
        const { localityId, ...params } = scope
        return {
          method: 'GET',
          url: `localities/${localityId}/openings`,
          params: {
            included: ['locality', 'position', 'organization', 'classification'],
            ...params,
          },
        }
      },
      transformResponse: (response) => {
        return {
          ...response,
          data: deserialize(response),
        }
      },
      providesTags: (result) => (result ? providesList(result.data, 'Opening') : []),
    }),
    allOrganizationOpenings: build.query({
      query(scope) {
        const { organizationId, ...params } = scope
        return {
          method: 'GET',
          url: `organizations/${organizationId}/openings`,
          params: {
            included: ['locality', 'position'],
            ...params,
          },
        }
      },
      transformResponse: (response) => {
        return {
          ...response,
          data: deserialize(response),
        }
      },
      providesTags: (result) => (result ? providesList(result.data, 'Opening') : []),
    }),
    positionOpenings: build.query({
      query(scope) {
        const { locality_id, position_id } = scope
        return {
          method: 'GET',
          url: `localities/${locality_id}/positions/${position_id}/openings`,
        }
      },
      transformResponse: (response) => {
        return {
          ...response,
          data: deserialize(response),
        }
      },
      providesTags: (result) => (result ? providesList(result.data, 'Opening') : []),
    }),
    createOpening: build.mutation({
      query(data) {
        const { locality_id, position_id, ...attributes } = data
        return {
          method: 'POST',
          url: `localities/${locality_id}/positions/${position_id}/openings`,
          body: {
            data: {
              attributes,
            },
          },
        }
      },
      invalidatesTags: [{ type: 'Opening', id: 'LIST' }],
    }),
    updateOpening: build.mutation({
      query(data) {
        const { id, ...attributes } = data
        return {
          method: 'PATCH',
          url: `openings/${id}`,
          body: {
            data: {
              attributes,
            },
          },
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Opening', id }],
    }),
    deleteOpening: build.mutation({
      query(id) {
        return {
          method: 'DELETE',
          url: `openings/${id}`,
        }
      },
      invalidatesTags: (result, error, id) => [{ type: 'Opening', id }],
    }),
    openingApplication: build.query({
      query(data) {
        const { openingId, ...attributes } = data
        return {
          method: 'POST',
          url: `openings/${openingId}/retainments`,
          body: {
            data: {
              attributes,
            },
          },
        }
      },
    }),
    getOneOpening: build.query({
      query(id) {
        return {
          method: 'GET',
          url: `openings/${id}`,
          params: {
            included: ['position', 'locality', 'organization', 'organization.configuration'],
          },
        }
      },
      transformResponse: (response) => {
        return {
          ...response,
          data: deserialize(response),
        }
      },
    }),
  }),

  overrideExisting: false,
})

export const {
  useGetOneOpeningQuery,
  useOpeningApplicationQuery,
  useAllOpeningsQuery,
  useAllOrganizationOpeningsQuery,
  usePositionOpeningsQuery,
  useCreateOpeningMutation,
  useUpdateOpeningMutation,
  useDeleteOpeningMutation,
} = openingsApi
