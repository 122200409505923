import { DataGrid, DataGridProps } from '@mui/x-data-grid'
import { DescendSortIcon, AscendSortIcon, UnsortedIcon } from '.'

interface KrowTableProps extends DataGridProps {}

function KrowTable(props: KrowTableProps) {
  return (
    <DataGrid
      disableColumnSelector
      disableColumnMenu
      {...props}
      getRowHeight={() => 'auto'}
      components={{
        ColumnSortedDescendingIcon: DescendSortIcon,
        ColumnSortedAscendingIcon: AscendSortIcon,
        ColumnUnsortedIcon: UnsortedIcon,
      }}
    />
  )
}

export default KrowTable
